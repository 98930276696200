export const useGQlData = async (query: string, variables: any = {}, key: any = null) => {
  const config = useRuntimeConfig();
  const basicAuth: string = btoa(`${config.wpUsername}:${config.wpPassword}`);
  const options: any = {
    headers: {
      'Authorization': `Basic ${basicAuth}`,
      'Content-Type': 'application/json'
    },
    body: {query, variables},
    method: 'POST',
    server: true,
  };

  if (key) {
    options.key = key;
  }

  const {data} : any = await useFetch(config.public.gql_endpoint, options);

  return data?.value?.data || {};
};
