const featuredImageFields = `
    featuredImage {
        node {
            srcSet
            mediaItemUrl
            title
            altText
        }
    }
`;

export const ourWorksQuery = `
    query {
        works(where: {orderby: {field: DATE, order: ASC}}) {
            nodes {
                title
                ${featuredImageFields}
                worksDetails {
                    buttonLabel
                    platform
                    link {
                      url
                    }
                }
            }
        }
    }
`;

export const ourServicesQuery = `
    query {
        services(where: {orderby: {field: DATE, order: ASC}}) {
            nodes {
                ${featuredImageFields}
                title
                content
                serviceDetails {
                  imagePosition
                }
            }
        }
    }
`;

export const techPartnersQuery = `
    query {
        techPartners(where: {orderby: {field: DATE, order: ASC}}) {
            nodes {
                ${featuredImageFields}
                title
            }
        }
    }
`;

export const clientsQuery = `
    query {
        clients(where: {orderby: {field: DATE, order: ASC}}) {
            nodes {
                ${featuredImageFields}
                title
            }
        }
    }
`;

export const blogsQuery = `
    query {
        posts(where: {orderby: {field: DATE, order: DESC}, status: PUBLISH}, first: 3) {
            nodes {
                title
                slug
                ${featuredImageFields}
                date
                categories {
                    nodes {
                        slug
                        link
                        name
                    }
                }
            }
        }
    }
`;

export const blogPageQuery = `
query getPosts($after: String) {
  posts(
    where: {orderby: {field: DATE, order: DESC}, status: PUBLISH}
    after: $after
    first: 12
  ) {
    nodes {
      id
      title
      slug
      dateGmt
      featuredImage {
        node {
          mediaItemUrl
          srcSet
          title
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`;

export const headerMenuQuery = `
{
  menu(id: "main-menu", idType: SLUG) {
    id
    name
    menuItems(where: {parentDatabaseId: 0}) {
      nodes {
        uri
        label
        childItems {
          nodes {
            uri
            label
          }
        }
      }
    }
  }
}
`;

export const reviewsQuery = `
    query  {
        reviews {
            nodes {
                title
                reviewDetails {
                    name
                    company
                    testimony
                    title
                    video {
                        url
                    }
                }
            }
        }
    }
`;

export const homePageQuery = `
    query  {
        page(id: "home", idType: URI) {
            seo {
                title
                canonical
                metaDesc
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphTitle
                opengraphUrl
                opengraphSiteName
                opengraphDescription
                opengraphModifiedTime
                opengraphType
                opengraphImage {
                mediaItemUrl
                mimeType
                mediaDetails {
                        width
                        height
                    }
                }
                schema {
                    raw
                }
                twitterImage {
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
            form {
                form
                message
                title
                image {
                    node {
                        mediaItemUrl
                        srcSet
                        title
                    }
                }
            }
        }
    }
`;

export const siteOptionsQuery = `
{
  page(id: "home", idType: URI) {
    siteOptions {
      logo {
        node {
          mediaItemUrl
        }
      }
      favicon {
        node {
          mimeType
          mediaItemUrl
        }
      }
    }
  }
}
`;

export const postBySlugQuery = `
query getPostBySlug($slug: ID!) {
  post(id: $slug, idType:SLUG) {
    ${featuredImageFields}
    id
    dateGmt
    slug
    title
    content
    categories {
      nodes {
        slug
        name
      }
    }
  }
}
`;

export const pagesQuery = `
query pages ($after: String) {
  pages(
    where: {orderby: {field: DATE, order: ASC}, status: PUBLISH}
    after: $after
    first: 20
  ) {
    nodes {
      uri
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`;


export const pageByUriQuery = `
query getPageByUri($uri: ID!) {
  page(id: $uri, idType: URI) {
    title
    content
  }
}
`;
